import firebase from 'firebase';
import { ref } from 'vue';
import router from '@/router';

export default function fire() {
  // eslint-disable-next-line
  const firestore = firebase.firestore();
  // mutations
  // eslint-disable-next-line
  let loading = ref(false);
  // eslint-disable-next-line
  let snap = ref([]);
  // auth
  function auth() {
    return firebase.auth().currentUser;
  }
  // sign in
  async function signIn(data) {
    loading.value = true;
    await new Promise((r) => setTimeout(r, 500));
    try {
      await firebase.auth().signInWithEmailAndPassword(data.email, data.password);
      loading.value = false;
      await router.replace({ name: 'dashboard' });
    } catch (e) {
      loading.value = false;
      // eslint-disable-next-line
      data.error = e.message;
    }
  }
  // sign out
  async function signOut() {
    loading.value = true;
    await firebase.auth().signOut();
    await router.replace({ name: 'auth' });
    loading.value = false;
  }
  // store record
  async function logStore(data) {
    await firestore.collection('logs').add({
      email: firebase.auth().currentUser.email,
      vat: data.vat,
      result: data.result,
      created_at: firebase.firestore.Timestamp.now(),
    }).then((res) => {
      console.log(res);
    }).catch((err) => {
      console.log(err);
    });
  }
  // get records
  async function logGet() {
    firestore.collection('logs').orderBy('created_at', 'desc').onSnapshot((r) => {
      r.docChanges().forEach((change) => {
        const {
          newIndex,
          oldIndex,
          doc,
          type,
        } = change;
        if (type === 'added') {
          snap.value.splice(newIndex, 0, doc.data());
          // if we want to handle references we would do it here
        } else if (type === 'modified') {
          // remove the old one first
          snap.value.splice(oldIndex, 1);
          // if we want to handle references we would have to unsubscribe
          // from old references' listeners and subscribe to the new ones
          snap.value.splice(newIndex, 0, doc.data());
        } else if (type === 'removed') {
          snap.value.splice(oldIndex, 1);
          // if we want to handle references we need to unsubscribe
          // from old references
        }
      });
    });
  }
  // post fiscal code
  async function logCreate(arg) {
    /* eslint-disable */
    const token = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)
    return fetch(`https://bilanci.innexta.it/api/store`,{
      method: 'post',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
      }),
      body: JSON.stringify({ data: { arg: arg }})
    }).then((res) => {
      return res.json()
    }).catch((err) => {
      return err
    });
  }
  // export
  return {
    auth,
    logGet,
    logStore,
    logCreate,
    signIn,
    signOut,
    snap,
    loading,
  };
}
