import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      todos: ['test'],
    };
  },
  mutations: {
    addTodo(state, item) {
      state.todos.unshift(item);
    },
  },
});
