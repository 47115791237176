import axios from 'axios';

const environment = process.env;

export default function api() {
  // get from registro imprese
  async function getBudget(param) {
    return axios.get(`${environment.VUE_APP_REST_API_URL}?codiceFiscale=${param}`, {
      headers: {
        username: 'TXHST7A',
        password: 'Scarlinn21!',
      },
    })
      .then((response) => response)
      .catch((error) => error.response);
  }

  return {
    getBudget,
  };
}
