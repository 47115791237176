<template>
  <div>
  <input
    v-bind="$attrs"
    :type="type"
    :required="required"
    :placeholder="placeholder + (required ? '*' : '')"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  ></div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    modelValue: {
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  setup(props) {
    return {
      props,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/components/input.scss";
</style>
