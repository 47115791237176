import { createApp } from 'vue';
import firebase from 'firebase';
import App from './App.vue';
import router from './router';
import store from './store';

firebase.initializeApp({
  apiKey: 'AIzaSyB2ZWMQseMAOv31eoMCAnpIyxyHm5R-sQ8',
  authDomain: 'innexta-panel.firebaseapp.com',
  databaseURL: 'https://innexta-panel.firebaseio.com',
  projectId: 'innexta-panel',
  storageBucket: 'innexta-panel.appspot.com',
  messagingSenderId: '969524660245',
  appId: '1:969524660245:web:72dcbe213e1fe9ace0a61c',
  measurementId: 'G-D5XGPKKCKE',
});

let app;
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App).use(store).use(router)
      .mount('#app');
  }
});
