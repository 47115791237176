<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script>
import AppLayout from '@/layouts/default.vue';

export default {
  name: 'Home',
  components: {
    AppLayout,
  },
};
</script>

<style lang="scss">
@import "~@/styles/style.scss";
</style>
