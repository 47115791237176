<template>
  <div class="layout">
    <div class="container-fluid">
      <div class="row justify-content-center min-vh-100">
        <div class="col-12">
          <div class="d-flex flex-column h-100">
            <div class="row justify-content-center align-items-stretch flex-grow-1">
              <div class="col-12">
                <slot/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/styles/layouts/default.scss";
</style>
