<template>
  <div class="row h-100 align-items-stretch justify-content-center">
    <div class="col-12 dashboard">
      <div class="row">
        <div class="col-6" style="height: 100vh; overflow: scroll">
          <div class="row search p-5 align-items-center">
            <form @submit.prevent="search(data.vat)" class="col-12">
              <div class="row align-items-center">
                <div class="col">
                  <app-input
                    required
                    placeholder="Inserisci la partita IVA"
                    autocomplete="vat"
                    v-model="data.vat"
                  ></app-input>
                </div>
                <div>
                  <button type="submit">
                    <img src="@/assets/icons/search.svg" v-if="!data.loading">
                    <div class="spinner-border text-white" v-else role="status"></div>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="row">
            <div class="col-12 title">
              <div class="row pr-5 pl-5 pt-3 pb-3">
                <div class="col-12">
                  <strong>Ultime ricerche</strong>
                </div>
              </div>
            </div>
            <div class="col-12 elm pb-3" v-for="(item, index) in snap" :key="index">
              <div class="row pr-5 pl-5">
                <div class="col-12 pt-3 pb-3"><i>{{ item.email || '-' }}</i></div>
                <div class="col-12 pb-1 accent">{{item.vat}}</div>
                <div class="col-6">
                  <small>{{new Date(item.created_at.toDate()).toLocaleDateString('it-IT')}}</small>
                </div>
                <div class="col-6 text-right text-primary valid" v-if="item.result">
                  <small>Trovata</small>
                </div>
                <div class="col-6 text-right error" v-if="!item.result">
                  <small>Non Trovata</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 cover">
          <div class="row h-100 align-items-center">
            <div class="col-12 p-3 pb-2 text-white text-center">
              <div class="box" v-if="data.result">
                <div class="row h-100 align-items-center">
                  <div class="col">
                    <div class="text-secondary pb-4">Download <br/> bilancio</div>
                    <button @click="download(data.result.xbrl, data.vat)">
                      <img src="@/assets/icons/download.svg">
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div style="position: absolute;bottom: 16px; right: 16px;" class="text-white">
              <h6>© 2020 Innexta. Consorzio Camerale per il Credito e la Finanza</h6>
            </div>
            <div style="position: absolute;bottom: 16px; left: 16px;" class="text-white">
              <h6 @click="signOut">Logout</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue';
import api from '@/services/api';
import pdf from '@/services/pdf';
// eslint-disable-next-line
import fire from "@/services/firebase";
import AppInput from '@/components/input.vue';

export default {
  components: { AppInput },
  setup() {
    const data = reactive({
      vat: null,
      loading: false,
      result: null,
      name: null,
    });
    // eslint-disable-next-line
    let { snap, auth, logGet, logStore, logCreate, signOut } = fire();
    // eslint-disable-next-line
    const { getBudget } = api();
    // eslint-disable-next-line
    const { parse, download } = pdf();

    logGet();

    function b64DecodeUnicode(str) {
      // Going backwards: from bytestream, to percent-encoding, to original string.
      /* eslint-disable */
      return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
    }

    async function search(arg) {
      data.loading = true;
      data.result = null;
      logCreate(arg).then(async (res) => {
        const json = await parse(res.data);
        if (json.Risposta.dati && json.Risposta.dati.length > 0) {
          data.result = await parse(b64DecodeUnicode(json.Risposta.dati[0]));
          await logStore({ vat: arg, result: true });
        } else {
          await logStore({ vat: arg, result: false });
        }
        data.loading = false;
      }).catch(() => {
        logStore({ vat: arg, result: false });
        data.loading = false;
      });
    }

    return {
      auth,
      search,
      download,
      data,
      snap,
      getBudget,
      signOut,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/views/dashboard.scss";
</style>
