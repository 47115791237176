<template>
  <div class="row h-100 align-items-stretch justify-content-center">
    <div class="col-12 auth">
      <div class="row h-100">
        <div class="col-6 p-5 d-flex align-items-center">
          <form class="row align-items-center" @submit.prevent="signIn(data)">
            <div class="col-12 pb-4 text-center">
              <img src="~@/assets/logo.png" class="logo">
            </div>
            <div class="col-12 pb-4">
              <app-input
                required
                placeholder="E-Mail"
                autocomplete="email"
                v-model="data.email"
              ></app-input>
            </div>
            <div class="col-12 pb-4">
              <app-input
                required type="password"
                placeholder="Password"
                autocomplete="current-password"
                v-model="data.password"
              ></app-input>
            </div>
            <div class="col-12 text-right">
              <div class="row align-items-center">
                <div class="col-8 text-primary text-left">
                  <h6>{{data.error}}</h6>
                </div>
                <div class="col-4 text-right">
                  <button type="submit">
                    <img src="@/assets/icons/check.svg" v-if="!loading">
                    <div class="spinner-border text-white" v-else role="status"></div>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-6 cover">
          <div class="row h-100 align-items-end">
            <div class="col-12 p-3 pb-2 text-white text-right">
              <h6>© 2020 Innexta. Consorzio Camerale per il Credito e la Finanza</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue';
// eslint-disable-next-line import/no-cycle
import fire from '@/services/firebase';
import AppInput from '@/components/input.vue';

export default {
  components: { AppInput },
  setup() {
    const data = reactive({
      email: null,
      password: null,
      error: null,
      user: null,
    });
    // eslint-disable-next-line
    let { loading, signIn } = fire();
    return {
      signIn, loading, data,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/views/auth.scss";
</style>
