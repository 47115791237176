import xml2js from 'xml2js';
import html2pdf from 'html2pdf.js';

export default function pdf() {
  /* eslint-disable */
  const parseString = xml2js.parseString;
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
  }
  function val(param, format = "") {
    if (param) {
      switch (param[0]['_']) {
        case "true":
          return "SI" + format
        case "false":
          return "NO" + format
        case "-":
          return "-"
        default:
          if (format !== "") {
            if(param[0]['_'].indexOf('.') !== -1)
            {
              // remove decimal
              let str = param[0]['_'].split('.')
              return numberWithCommas(str[0]) + format;
            }
            return numberWithCommas(param[0]['_']) + format;
          } else {
            return param[0]['_'] + format;
          }
      }
    }
    return '-';
  }
  function date() {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');
  }
  function convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    let d = new Date(inputFormat)
    return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
  }
  async function parse(xml) {
    return new Promise((resolve, reject) => {
      parseString(xml, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }
  async function download(data, name) {
    console.log(data['itcc-ci:DatiAnagraficiFormaGiuridica'])
    // eslint-disable-next-line
    let endDate = null
    let date = null
    let l = data.context.length
    for (let i = 0; i <= l-1; i++) {
      if(data.context[i].period != undefined && data.context[i].period[0].endDate != undefined) {
        let d = data.context[i].period[0].endDate[0].split('/').join(' ')
        let dd = new Date(d);
        if (dd > date) {
          date = dd
          endDate = data.context[i].period[0].endDate[0];
        }
      }
    }
    let opt = {
      margin:       1,
      filename:     name + '.pdf',
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { scale: 2},
      pagebreak: { mode: 'avoid-all', },
      jsPDF: {orientation: 'portrait', unit: 'in', format: 'letter', compressPDF: true}
    };
    /* eslint-disable */
    html2pdf().from('<!doctype html>' +
      '<html>' +
      '<head>' +
      '  <style>' +
      '    body {' +
      '      margin: 0;' +
      '      padding: 0;' +
      '      font-family: "Roboto", Helvetica, Arial, "Lucida Grande", sans-serif;' +
      '      color: #666;' +
      '      font-weight: lighter;' +
      '    }' +
      '    table {' +
      '      width: 100%;' +
      '      padding: 0;' +
      '    }' +
      '.fullpage {' +
      '   height: 9in;' +
      '}' +
      '.image-container {' +
      '      text-align: center;' +
      '      padding-top: 60px;' +
      '    }' +
      '.prospetto_contabile {' +
      '      font-size: 20px;' +
      '      font-weight: lighter;' +
      '      margin-top: 70px;' +
      '}' +
      '.nome_azienda{' +
      '        font-size: 40px;' +
      '        font-weight: lighter;' +
      '        margin-top: 10px;' +
      '}' +
      '.data{' +
      '        font-size: 25px;' +
      'color: black !important;' +
      '        margin-top: 200px;' +
      '        font-weight: 600;' +
      '}' +
      '    th {' +
      '      width: 50%;' +
      '      padding: 0;' +
      '    }' +
      '    tr th:nth-child(odd) {' +
      '      text-align: left;' +
      '      padding: 0;' +
      '    }' +
      '    tr th:nth-child(even) {' +
      '      text-align: right;' +
      '      padding: 0;' +
      '    }' +
      '    .no_border{' +
      '      border: 0 !important;' +
      '      margin-bottom:10px !important;' +
      '    }' +
      '    .header {' +
      '      height: 45px;' +
      '      line-height:20px;' +
      '      margin: 0 20px 5px 20px;' +
      '      background-color: #62BBEA;' +
      '      color: #fff;' +
      '      font-size: 15px;' +
      '      padding: 13px 7px 7px 7px;' +
      '      text-transform: uppercase;' +
      '      font-weight: bold;' +
      '    }' +
      '    .riga {' +
      'padding-bottom:10px;' +
      '      font-size: 12px;' +
      'line-height: 20px;' +
      '      margin: 0 20px;' +
      '      border-bottom: 1px solid #bbb;' +
      '      background-color: transparent;' +
      '      color: #666;' +
      '    }' +
      '  </style>' +
      '</head>' +
      '<body>' +
      '<div class="image-container fullpage">' +
      '    <p class="prospetto_contabile" style="color:black; font-weight: 400; font-size: 30px">Dati di bilancio</p>' +
      '    <p class="nome_azienda" style="color:black; font-weight: 500; text-transform: uppercase; font-size: 40px">' +
      val(data['itcc-ci:DatiAnagraficiDenominazione']) +
      '</p>' +
      '</div>' +
      '<div class="header">' +
      '  Dati anagrafici' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Sede</th>' +
      '      <th>' + val(data['itcc-ci:DatiAnagraficiSede']) + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Capitale sociale</th>' +
      '      <th>' + val(data['itcc-ci:DatiAnagraficiCapitaleSociale'], ' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Capitale sociale interamente versato</th>' +
      '      <th>' + val(data['itcc-ci:DatiAnagraficiCapitaleSocialeInteramenteVersato']) + '</tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Codice cciaa</th>' +
      '      <th>' + val(data['itcc-ci:DatiAnagraficiCodiceCciaa']) + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Partita iva</th>' +
      '      <th>' + val(data['itcc-ci:DatiAnagraficiPartitaIva']) + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Codice fiscale</th>' +
      '      <th>' + val(data['itcc-ci:DatiAnagraficiCodiceFiscale']) + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Numero rea</th>' +
      '      <th>' + val(data['itcc-ci:DatiAnagraficiNumeroRea']) + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Forma giuridica</th>' +
      '      <th>' + val(data['itcc-ci:DatiAnagraficiFormaGiuridica']) + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Settore di attività prevalente (ateco)</th>' +
      '      <th>' + val(data['itcc-ci:DatiAnagraficiSettoreAttivitaPrevalenteAteco']) + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Società in liquidazione</th>' +
      '      <th>' + val(data['itcc-ci:DatiAnagraficiSocietaLiquidazione']) + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Società con socio unico</th>' +
      '      <th>' + val(data['itcc-ci:DatiAnagraficiSocietaSocioUnico']) + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Società sottoposta ad altrui attività di direzione e coordinamento</th>' +
      '      <th>' + val(data['itcc-ci:DatiAnagraficiSocietaSottopostaAltruiAttivitaDirezioneCoordinamento']) + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Denominazione della società o ente che esercita l\'attività di direzione e coordinamento</th>' +
      '      <th>' + val(data['itcc-ci:DatiAnagraficiDenominazioneSocietaEnteEsercitaAttivitaDirezioneCoordinamento']) + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Appartenenza a un gruppo</th>' +
      '      <th>' + val(data['itcc-ci:DatiAnagraficiAppartenenzaGruppo']) + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Denominazione della società capogruppo</th>' +
      '      <th>' + val(data['itcc-ci:DatiAnagraficiDenominazioneSocietaCapogruppo']) + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Paese della capogruppo</th>' +
      '      <th>' + val(data['itcc-ci:DatiAnagraficiPaeseCapogruppo']) + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div style="height: 4px">' +
      '  <table>' +
      '    <tr>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div style="height: 4px">' +
      '  <table>' +
      '    <tr>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="header">' +
      '  stato patrimoniale' +
      '</div>' +
      '' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>B - Totale immobilizzazioni</th>' +
      '      <th>' + val(data['itcc-ci:TotaleImmobilizzazioni'], ' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>C.I - Totale rimanenze</th>' +
      '      <th>' + val(data['itcc-ci:TotaleRimanenze'], ' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>C.II - Crediti, esigibili entro l\'esercizio successivo</th>' +
      '      <th>' + val(data['itcc-ci:CreditiEsigibiliEntroEsercizioSuccessivo'], ' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>C.II - Crediti, esigibili oltre l\'esercizio successivo</th>' +
      '      <th>' + val(data['itcc-ci:CreditiEsigibiliOltreEsercizioSuccessivo'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>C.II - Totale crediti</th>' +
      '      <th>' + val(data['itcc-ci:TotaleCrediti'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>C.IV - Totale disponibilità liquide</th>' +
      '      <th>' + val(data['itcc-ci:TotaleDisponibilitaLiquide'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>C - Totale attivo circolante</th>' +
      '      <th>' + val(data['itcc-ci:TotaleAttivoCircolante'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Totale attivo</th>' +
      '      <th>' + val(data['itcc-ci:TotaleAttivo'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>A.I - Patrimonio netto, capitale</th>' +
      '      <th>' + val(data['itcc-ci:PatrimonioNettoCapitale'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>A - Patrimonio netto, perdita ripianata nell\'esercizio</th>' +
      '      <th>' + val(data['itcc-ci:PatrimonioNettoPerditaRipianataEsercizio'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>A - Totale patrimonio netto</th>' +
      '      <th>' + val(data['itcc-ci:TotalePatrimonioNetto'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>D - Debiti, esigibili entro l\'esercizio successivo</th>' +
      '      <th>' + val(data['itcc-ci:DebitiEsigibiliEntroEsercizioSuccessivo'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>D - Debiti, esigibili oltre l\'esercizio successivo</th>' +
      '      <th>' + val(data['itcc-ci:DebitiEsigibiliOltreEsercizioSuccessivo'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>D - Totale debiti</th>' +
      '      <th>' + val(data['itcc-ci:TotaleDebiti'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga no_border">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Totale passivo</th>' +
      '      <th>' + val(data['itcc-ci:TotalePassivo'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '' +
      '<div class="header">' +
      '  conto economico' +
      '</div>' +
      '' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>A.1 - Valore della produzione, ricavi delle vendite e delle prestazioni</th>' +
      '      <th>' + val(data['itcc-ci:ValoreProduzioneRicaviVenditePrestazioni'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>A - Totale valore della produzione</th>' +
      '      <th>' + val(data['itcc-ci:TotaleValoreProduzione'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>B.6 - Costi della produzione, per materie prime, sussidiarie, di consumo e di merci</th>' +
      '      <th>' + val(data['itcc-ci:CostiProduzioneMateriePrimeSussidiarieConsumoMerci'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>B.7 - Costi della produzione, per servizi</th>' +
      '      <th>' + val(data['itcc-ci:CostiProduzioneServizi'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>B.9 - Costi della produzione, per il personale, totale costi per il personale</th>' +
      '      <th>' + val(data['itcc-ci:CostiProduzionePersonaleTotaleCostiPersonale'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>B - Totale costi della produzione</th>' +
      '      <th>' + val(data['itcc-ci:TotaleCostiProduzione'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Differenza tra valore e costi della produzione</th>' +
      '      <th>' + val(data['itcc-ci:DifferenzaValoreCostiProduzione'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>C - Totale proventi e oneri finanziari</th>' +
      '      <th>' + val(data['itcc-ci:TotaleProventiOneriFinanziari'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Risultato prima delle imposte</th>' +
      '      <th>' + val(data['itcc-ci:RisultatoPrimaImposte'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>20 - Imposte sul reddito dell\'esercizio, correnti, differite e anticipate, totale delle' +
      '        imposte sul reddito dell\'esercizio, correnti, differite e anticipate</th>' +
      '      <th>' + val(data['itcc-ci:ImposteRedditoEsercizioCorrentiDifferiteAnticipateTotaleImposteRedditoEsercizioCorrentiDifferiteAnticipate'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>21 - Utile (perdita) dell\'esercizio</th>' +
      '      <th>' + val(data['itcc-ci:UtilePerditaEsercizio'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Utile (perdita) dell\'esercizio prima d\'imposte sul reddito, interessi, dividendi e plus/mi-' +
      '        nusvalenze da cessione</th>' +
      '      <th>' + val(data['itcc-ci:UtilePerditaEsercizioPrimaImposteRedditoInteressiDividendiPlusminusvalenzeCessione'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga" style="margin-top:0px">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Flusso finanziario prima delle variazioni del capitale circolante netto</th>' +
      '      <th>' + val(data['itcc-ci:FlussoFinanziarioPrimaVariazioniCapitaleCircolanteNetto'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Flusso finanziario dopo le variazioni del capitale circolante netto</th>' +
      '      <th>' + val(data['itcc-ci:FlussoFinanziarioDopoVariazioniCapitaleCircolanteNetto'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Incremento/(Decremento) debiti a breve verso banche</th>' +
      '      <th>' + val(data['itcc-ci:IncrementoDecrementoDebitiBreveVersoBanche'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Accensione finanziamenti</th>' +
      '      <th>' + val(data['itcc-ci:AccensioneFinanziamenti'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '<div class="riga">' +
      '  <table>' +
      '    <tr>' +
      '      <th>Totale disponibilità liquide</th>' +
      '      <th>' + val(data['itcc-ci:TotaleDisponibilitaLiquide'],' €') + '</th>' +
      '    </tr>' +
      '  </table>' +
      '</div>' +
      '</table>' +
      '</body>' +
      '</html>').set(opt).toPdf().get('pdf').then(function (pdf) {
      let totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        if(i > 1) {
          pdf.setFontSize(10);
          pdf.setTextColor(0);
          pdf.text(1.20, 0.25, "Dati disponibili al: " + convertDate(date));
        }
      }

    }).save();
  }
  return {
    parse,
    download,
  };
}
